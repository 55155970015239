import React from "react"
import Link from "../components/Link"
import Layout from "../components/layout"
import Button from "../components/Button"
import Img404 from "../images/img_404.svg"

const NotFoundPage = () => (
  <Layout showTitleTemplate={false}>
    <div className="flex flex-col items-center justify-center py-24 sm:py-32">
      <img src={Img404} alt="img_404" className="h-16 sm:h-24" />
      <p className="text-neutral-500 text-xl sm:text-2xl leading-normal tracking-wide mt-6">
        頁面不存在或維護中
      </p>
      <div className="mt-6">
        <Link to="/">
          <Button size="md">回首頁</Button>
        </Link>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
